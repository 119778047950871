.center-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    flex-direction: column;
    /* background-color: rgba(0, 0, 0, 0.9); */
    backdrop-filter: blur(5px);
}

/* body {
    background: #202628;
} */

.loader-square-53 {
    height: 300px;
    width: 300px;
    position: relative;
}

.loader-square-53 > div {
    border-radius: 10px;
    position: absolute;
}

.loader-square-53 .sl-53-yellow {
    background-color: #FDBE45;
    width: 40px;
    height: 40px;
    top: 10%;
    left: 10%;
    animation: anm-SL-53-Yellow 4s ease infinite;
}

.loader-square-53 .sl-53-blue {
    background-color: #224BF7;
    width: 35px;
    height: 35px;
    top: 20%;
    left: 70%;
    animation: anm-SL-53-Blue 5s ease infinite;
}

.loader-square-53 .sl-53-magenta {
    background-color: #D001DD;
    width: 45px;
    height: 45px;
    top: 70%;
    left: 70%;
    animation: anm-SL-53-Magenta 4.8s ease infinite;
}

.loader-square-53 .sl-53-red {
    background-color: #D11533;
    width: 60px;
    height: 60px;
    top: 70%;
    left: 10%;
    animation: anm-SL-53-Red 4.5s ease infinite;
}

.loader-square-53 .sl-53-cyan {
    background-color: #66BAFF;
    width: 55px;
    height: 55px;
    top: 40%;
    left: 40%;
    animation: anm-SL-53-Cyan 5.2s ease infinite;
}

.loader-square-53 .sl-53-green {
    background-color: #16A319;  /* Pure Green Color */
    width: 45px;
    height: 45px;
    top: 10%;
    left: 45%;
    animation: anm-SL-53-Green 5s ease infinite;
}

/* Animations for the squares */
@keyframes anm-SL-53-Yellow {
    0%, 100% {
        top: 10%;
        left: 10%;
        transform: scale(0.5) rotate(0deg);
        filter: brightness(0.8);
    }
    12% {
        top: 35%;
        left: 30%;
        transform: scale(0.8) rotate(28deg);
        filter: brightness(1.1);
    }
    46% {
        top: 20%;
        left: 50%;
        transform: scale(1) rotate(150deg);
        filter: brightness(0.7);
    }
    67% {
        top: 15%;
        left: 15%;
        transform: scale(1.2) rotate(95deg);
        filter: brightness(0.9);
    }
}

@keyframes anm-SL-53-Blue {
    0%, 100% {
        top: 20%;
        left: 70%;
        transform: scale(0.5) rotate(10deg);
        filter: brightness(0.9);
    }
    21% {
        top: 10%;
        left: 75%;
        transform: scale(0.8) rotate(70deg);
        filter: brightness(1.2);
    }
    53% {
        top: 40%;
        left: 60%;
        transform: scale(1) rotate(190deg);
        filter: brightness(0.8);
    }
    80% {
        top: 10%;
        left: 90%;
        transform: scale(1.2) rotate(200deg);
        filter: brightness(0.9);
    }
}

@keyframes anm-SL-53-Magenta {
    0%, 100% {
        top: 70%;
        left: 70%;
        transform: scale(0.5) rotate(30deg);
        filter: brightness(0.85);
    }
    18% {
        top: 75%;
        left: 60%;
        transform: scale(0.8) rotate(65deg);
        filter: brightness(1.05);
    }
    39% {
        top: 85%;
        left: 80%;
        transform: scale(1) rotate(155deg);
        filter: brightness(0.75);
    }
    73% {
        top: 65%;
        left: 75%;
        transform: scale(1.2) rotate(210deg);
        filter: brightness(0.9);
    }
}

@keyframes anm-SL-53-Red {
    0%, 100% {
        top: 70%;
        left: 10%;
        transform: scale(0.5) rotate(20deg);
        filter: brightness(0.7);
    }
    14% {
        top: 65%;
        left: 5%;
        transform: scale(0.8) rotate(50deg);
        filter: brightness(1);
    }
    47% {
        top: 85%;
        left: 25%;
        transform: scale(1) rotate(140deg);
        filter: brightness(0.6);
    }
    89% {
        top: 60%;
        left: 5%;
        transform: scale(1.2) rotate(230deg);
        filter: brightness(0.8);
    }
}

@keyframes anm-SL-53-Cyan {
    0%, 100% {
        top: 40%;
        left: 40%;
        transform: scale(0.5) rotate(40deg);
        filter: brightness(0.75);
    }
    23% {
        top: 55%;
        left: 35%;
        transform: scale(0.8) rotate(85deg);
        filter: brightness(1.25);
    }
    56% {
        top: 25%;
        left: 45%;
        transform: scale(1) rotate(200deg);
        filter: brightness(0.55);
    }
    78% {
        top: 45%;
        left: 35%;
        transform: scale(1.2) rotate(220deg);
        filter: brightness(1);
    }
}

@keyframes anm-SL-53-Green {
    0%, 100% {
        top: 10%;
        left: 45%;
        transform: scale(1.8) rotate(15deg);
        filter: brightness(0.9);
    }
    25% {
        top: 5%;
        left: 50%;
        transform: scale(1) rotate(85deg);
        filter: brightness(1.2);
    }
    50% {
        top: 10%;
        left: 35%;
        transform: scale(0.7) rotate(165deg);
        filter: brightness(0.8);
    }
    75% {
        top: 15%;
        left: 60%;
        transform: scale(1.3) rotate(245deg);
        filter: brightness(1);
    }
}