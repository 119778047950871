body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Avenir Next Cyr','Qanelas Soft', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'qanelas soft',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.toast div:last-child {
  justify-content: flex-start;
}
code {
  font-family: source-code-pro, Menlo, Courier New, Monaco, Consolas, 'Qanelas Soft','qanelas soft',
    monospace,;
}
